.greeting-body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #2D3250;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.greeting-container {
    position: relative;
    text-align: center;
    background-color: #202647;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-box h1 {
    font-size: 2.5em;
    color: #00a06d;
    margin-bottom: 20px;
}

.message-box p {
    font-size: 1.2em;
    color: #00a06d;
}

.progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f2f2f2;
    height: 10px;
    /* Adjust the height as needed */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.progress {
    width: 0;
    height: 100%;
    background-color: #00a06d;
    animation: progressAnimation 10s linear forwards;
}

@keyframes progressAnimation {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.message-box {
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}