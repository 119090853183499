@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

.navbar-container {
  display: flex;
  flex-direction: row;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 15px #1c1c1c66;
  width: 90%;
  height: 80px;
  border-radius: 18px;
  font-size: 1.2rem;
  position:fixed;
  top: 0.3rem;
  left:50%;
  transform: translate(-50%);
  background: #2d3250;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    /* justify-self: start; */
    /* justify-self: end; */
    /* justify-self: flex-end; */
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 5px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 3px solid #03C988;
  }
  .navbar-user{
    position: relative;
    left: 3rem;
  }
  .logoutButton{
    display: flex;
    flex-direction: column-reverse;
    background-color: #1c2237;
    /* height: 3rem; */
    /* margin-top:1rem; */
    visibility: hidden;
  }
  .navbar-user:hover{
    .profile-menu{visibility: visible;}
  }
  .nav-links {
    color: #c0c0c0;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  .nav-links.active{
    color: #03C988;
  }
  .nav-links:hover{
    color: #fff;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  .navbar-user{
    position: relative;
    left: 3rem;
    /* align-self: flex-end; */
    /* position:relative; */
    /* justify-self: ;   */
  }
  .profile-menu{
  position: absolute;
  top: 75px;
  right: -3px;
  padding: 8px 18px;
  background: #394063;
  box-shadow: #0000 5px 5px 15px;
  width: 300px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  visibility: hidden;
    li{
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }

  }
  
  @media screen and (max-width: 960px) {
    .navbar-container{
      top:0;
      width: 100%;
      border-radius: 0px;
      
    }
    .navbar-logo{
      position: absolute;
      box-sizing: 30;
      left: 0;
      transform: translate(2%, 2%);
      .img{
        width: 100;
        height: 55;
      }
    }
    .NavbarItems {
      justify-content: center;
      position: relative;
    }
    
    .nav-menu {
      display: flex;
      align-content: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 20;
    }
  
    .nav-menu.active {
      background: #282d48;
      top: 80px;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index:1000;
    }
  
    .nav-links {
      padding: 2rem;
      text-align: center;
      display: table;
    }
  
    .nav-links:hover {
      color: #03C988;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
    .navbar-user{
      visibility: hidden;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }

  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }