@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://gistcdn.githack.com/mfd/7c7a915eb31474cc8c6a65066a4c4dc3/raw/f0f2fb94c21dea904812a53e0eb6cf0bc87f3754/GTWalsheimPro.css');
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'GT Walsheim Pro', sans-serif;
}
:root{
  --hue: 223;
	--bg: #2D3250;
	--fg: #2D3250;
	--trans-dur: 0.3s;
	/* font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320)); */
}

*::-webkit-scrollbar {
  width: 1rem;
}
*::-webkit-scrollbar-track {
  background-color: #2e2e2e;
  border-radius: 2rem;
}
*::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: #1a1a1a;
}
input{
  background-color: inherit;
  color: inherit;
  border-style: none;
  border-radius: .3rem;
  padding: .3rem;
}
input:focus{
  color: none;
}

body::-webkit-scrollbar-track {
  background-color: #2e2e2e;
  border-radius: 0px;
}

  footer {
  z-index: 10;
  position: relative; /* Ensure it is positioned relative */
}
  